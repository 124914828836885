import { SelectValidate } from '@/components';
import { BodyForm } from '@/components/formValidate';
import useCompanyApplication from '@/core/hooks/useCompanyApplication';
import { ObjectType } from '@/shareds/types';
import { getToken } from '@/utils/authentication';
import React from 'react';
import { Beneficiary } from '../../entities';
import useSelectBeneficiary, { UseSelectBeneficiaryProps } from './hook/useSelectBeneficiary';

export interface SelectBeneficiaryProps extends BodyForm, UseSelectBeneficiaryProps {
  required?: boolean;
  disabled?: boolean;
  autoRequest?: boolean;
  fixedList?: boolean;
  name: string;
  label?: string;
  filterParams?: ObjectType;
}

const SelectBeneficiary: React.FC<SelectBeneficiaryProps> = ({
  required,
  disabled,
  autoRequest,
  name,
  label,
  onBlur,
  onChange,
  useErrors,
  useValues,
  filterParams,
  columns,
  fixedList = true,
}) => {
  const { companyId } = useCompanyApplication();
  const { availableColumns } = useSelectBeneficiary({ columns });

  const handleOnChange = (name: string, value: string, selectedBeneficiary: Beneficiary) => {
    onChange?.(name, value, selectedBeneficiary);
  };

  const requestParams: ObjectType = {
    companyId,
    ...filterParams,
  };

  return (
    <SelectValidate
      required={required}
      disabled={disabled}
      autoRequest={autoRequest}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      useErrors={useErrors}
      useValues={useValues}
      requestPageParam='page'
      requestResponseRootPath='data'
      requestResponseText='life.name'
      requestResponseValue='id'
      requestRouter='/beneficiaries'
      requestSearchParam='life.name'
      requestUri={process.env.REACT_APP_API_BENEFICIARIES_URL}
      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
      searchable
      customMessageInitial='Digite o nome do beneficiário no campo acima.'
      customMessageNotFound='Nenhum beneficiário com esse nome foi encontrado.'
      requestParams={requestParams}
      columns={availableColumns}
      fixedList={fixedList}
    />
  );
};

export default SelectBeneficiary;
