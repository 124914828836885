import { Contract, Negotiation, Product, isBradesco } from '@/modules/negotiation';
import { MARITAL_STATUS_FROM_LIFE_TO_MOVE } from '@/pages/beneficiaries/createOrUpdate/shareds/constants';
import { formatDateToMoveFile } from '@/pages/beneficiaries/createOrUpdate/shareds/utils/formatDateToMoveFile';
import { extractContactsFromBeneficiary } from '@/pages/beneficiaries/shareds/utils/extractContactsFromBeneficiary';
import { extractDocumentsFromBeneficiary } from '@/pages/beneficiaries/shareds/utils/extractDocumentsFromBeneficiary';
import { BENEFICIARY_OWNERSHIP, MARITAL_STATUS, MOVE_FILE_COLUMN_NAME } from '@/pages/moves/shareds/constants';
import { Address } from '@/services/beneficiaries/endpoints/addresses';
import { BankAccount } from '@/services/beneficiaries/endpoints/bankAccounts';
import { Beneficiary } from '@/services/beneficiaries/endpoints/beneficiaries';
import { contractInitialState } from '@/services/negotiations/endpoints/contracts';
import { costCenterInitialState } from '@/services/negotiations/endpoints/costCenter';
import { productInitialState } from '@/services/negotiations/endpoints/products';
import { getStateAbbr } from '@/shared/modules/address';
import { get, upperCase } from 'lodash';

const negotiationInitalState: Negotiation = {
  contractId: '',
  productId: '',
  brokerId: '',
  costCenterId: '',
  product: productInitialState as Product,
  contract: contractInitialState as Contract,
  costCenter: costCenterInitialState,
};

export const beneficiaryToMoveFileDTO = (
  beneficiary: Beneficiary,
  negotiation: Negotiation = negotiationInitalState,
  beneficiaryHolder?: Beneficiary,
) => {
  const lifeDocuments = extractDocumentsFromBeneficiary(beneficiary);
  const contacts = extractContactsFromBeneficiary(beneficiary);
  const address = (get(beneficiary, 'life.addresses[0]') || {}) as Address;
  const bankAccount = (get(beneficiary, 'bankAccount') || {}) as BankAccount;

  const beneficiaryToMoveFile: { [key in MOVE_FILE_COLUMN_NAME]?: string } = {
    [MOVE_FILE_COLUMN_NAME.TITULARIDADE]:
      beneficiary.beneficiaryHolder?.holderId !== beneficiary.id
        ? BENEFICIARY_OWNERSHIP.DEPENDENT
        : BENEFICIARY_OWNERSHIP.HOLDER,
    [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: negotiation.contract.code,
    [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: negotiation.costCenter.name,
    [MOVE_FILE_COLUMN_NAME.NOME]: beneficiary.life?.name,
    [MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO]: formatDateToMoveFile(beneficiary.life?.birthDate),
    [MOVE_FILE_COLUMN_NAME.MATRICULA]: '',
    [MOVE_FILE_COLUMN_NAME.CPF]: lifeDocuments.cpf,
    [MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO]: '',
    [MOVE_FILE_COLUMN_NAME.SEXO]: beneficiary.life?.gender,
    [MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL]: MARITAL_STATUS_FROM_LIFE_TO_MOVE[upperCase(beneficiary.life?.maritalStatus)] || MARITAL_STATUS.OTHERS,
    [MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA]: formatDateToMoveFile(beneficiary.validFrom),
    [MOVE_FILE_COLUMN_NAME.NOME_DA_MAE]: beneficiary.life?.motherName || '',
    [MOVE_FILE_COLUMN_NAME.CEP]: address.zipCode,
    [MOVE_FILE_COLUMN_NAME.UF]: getStateAbbr(address.state),
    [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: address.location,
    [MOVE_FILE_COLUMN_NAME.BAIRRO]: address.neighborhood || '',
    [MOVE_FILE_COLUMN_NAME.CIDADE]: address.city,
    [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: address.complement || '',
    [MOVE_FILE_COLUMN_NAME.NUMERO]: address.number || '',
    [MOVE_FILE_COLUMN_NAME.PLANO]: isBradesco(negotiation)
      ? negotiation.product.providerInternalCode
      : negotiation.product.name,
    [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: negotiation.product.name,
    [MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA]: bankAccount.type,
    [MOVE_FILE_COLUMN_NAME.BANCO]: bankAccount.bank,
    [MOVE_FILE_COLUMN_NAME.AGENCIA]: bankAccount.agencyNumber,
    [MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA]: bankAccount.agencyDigit,
    [MOVE_FILE_COLUMN_NAME.CONTA]: bankAccount.accountNumber,
    [MOVE_FILE_COLUMN_NAME.DV_DA_CONTA]: bankAccount.accountDigit,
    [MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR]: '',
    [MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO]: '',
    [MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO]: contacts.email,
    [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]: '',
    [MOVE_FILE_COLUMN_NAME.CNS]: '',
    [MOVE_FILE_COLUMN_NAME.LOTACAO]: '',
    [MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO]: '',
    [MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO]: '',
    [MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE]: '',
    [MOVE_FILE_COLUMN_NAME.MOTIVO_DA_DELECAO]: '',
    [MOVE_FILE_COLUMN_NAME.DATA_DE_CANCELAMENTO]: '',
    [MOVE_FILE_COLUMN_NAME.CERTIFICADO]: '',
    [MOVE_FILE_COLUMN_NAME.OPCAO_PELA_RN_279]: '',
    [MOVE_FILE_COLUMN_NAME.CODIGO_DO_DEPENDENTE]: '',
  };

  if (beneficiaryHolder) {
    const lifeHolderDocuments = extractDocumentsFromBeneficiary(beneficiaryHolder);
    beneficiaryToMoveFile[MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR] = lifeHolderDocuments.cpf;
  }

  return beneficiaryToMoveFile;
};
