import { MARITAL_STATUS } from '@/pages/moves/shareds/constants';

export const MARITAL_STATUS_FROM_LIFE_TO_MOVE: Record<string, MARITAL_STATUS> = {
  '1': MARITAL_STATUS.SINGLE,
  '2': MARITAL_STATUS.MARRIED,
  '3': MARITAL_STATUS.WIDOWER,
  S: MARITAL_STATUS.SINGLE,
  C: MARITAL_STATUS.MARRIED,
  V: MARITAL_STATUS.WIDOWER,
  SOLTEIRO: MARITAL_STATUS.SINGLE,
  CASADO: MARITAL_STATUS.MARRIED,
  VIUVO: MARITAL_STATUS.WIDOWER,
  SINGLE: MARITAL_STATUS.SINGLE,
  MARRIED: MARITAL_STATUS.MARRIED,
  WIDOWED: MARITAL_STATUS.WIDOWER,
};
