import { SelectValidate } from '@/components';
import BoxInfo from '@/components/boxInfo';
import { BodyForm } from '@/components/formValidate';
import GridGroup from '@/components/gridGroup';
import ShowHide from '@/components/showHide';
import TextFieldValidate from '@/components/textFieldValidate';
import { Details } from '@/modules/beneficiary';
import SelectBeneficiary from '@/modules/beneficiary/components/selectBeneficiary/selectBeneficiary';
import { Negotiation } from '@/modules/negotiation';
import { useHistoryParams } from '@/navigation';
import { beneficiaryToMoveFileDTO } from '@/pages/beneficiaries/createOrUpdate/shareds/utils/beneficiaryToMoveFileDTO';
import { formatDateToMoveFile } from '@/pages/beneficiaries/createOrUpdate/shareds/utils/formatDateToMoveFile';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import {
  BENEFICIARY_OWNERSHIP,
  COMPANY_LINK,
  MOVE_FILE_COLUMN_NAME,
} from '@/pages/moves/shareds/constants';
import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { getFieldNames } from '@/pages/moves/upload/utils/utils';
import { beneficiariesService } from '@/services/beneficiaries';
import { Beneficiary } from '@/services/beneficiaries/endpoints/beneficiaries';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import translateRulesValidator from '@/utils/translateRulesValidator';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Button, DataTable, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface SelectExistingBeneficiaryProps extends BodyForm {
  negotiationById: Record<string, Negotiation>;
  updateBeneficiaryToMove: (newData: BeneficiaryToMove) => void;
  setDataToCopy: (newData: BeneficiaryToMove) => void;
  disableFieldIfFilledIn?: BeneficiaryToMove;
  disableField: (key: keyof BeneficiaryToMove, value: boolean) => void;
  dataToMove: BeneficiaryToMove;
  holderErrorDetails?: MovesValidationErrorDetail[];
  setHolderErrorDetails?: Dispatch<SetStateAction<MovesValidationErrorDetail[]>>;
}

const AboutBeneficiaryHolderForm: React.FC<SelectExistingBeneficiaryProps> = ({
  updateBeneficiaryToMove,
  negotiationById,
  dataToMove,
  setDataToCopy,
  onBlur,
  useErrors,
  useValues,
  onChange,
  disableFieldIfFilledIn,
  disableField,
  holderErrorDetails = [],
  setHolderErrorDetails,
}) => {
  const { company } = useCompany();
  const { beneficiaryId } = useHistoryParams();

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const useRefDialog = React.useRef({ open: () => ({}), dismiss: () => ({}) });

  const spreadBeneficiaryToMoveFile = useCallback(
    (beneficiaryToMoveFile: BeneficiaryToMove, negotiationId?: string) => {
      updateBeneficiaryToMove({
        [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: beneficiaryToMoveFile[MOVE_FILE_COLUMN_NAME.CPF],
      });
      disableField(
        MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR,
        !!beneficiaryToMoveFile[MOVE_FILE_COLUMN_NAME.CPF],
      );
      setDataToCopy({
        ...beneficiaryToMoveFile,
        negotiationId,
      });
    },
    [disableField, setDataToCopy, updateBeneficiaryToMove],
  );

  const loadBeneficiaryHolder = useCallback(
    (beneficiaryIdToLoad?: string, companyIdToLoad?: string) => {
      tryOrCatchMessageError(
        async () => {
          if (!beneficiaryIdToLoad || !companyIdToLoad) {
            spreadBeneficiaryToMoveFile({});
            return;
          }

          const beneficiaryFound = await beneficiariesService.find(
            beneficiaryIdToLoad,
            companyIdToLoad,
          );
          const parsedBeneficiaryToMoveFile = beneficiaryToMoveFileDTO(
            beneficiaryFound,
            negotiationById[beneficiaryFound.negotiationId],
          );

          spreadBeneficiaryToMoveFile(
            {
              ...parsedBeneficiaryToMoveFile,
              [MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO]: formatDateToMoveFile(
                beneficiaryFound.validFrom,
              ),
              beneficiaryId: beneficiaryIdToLoad,
            },
            beneficiaryFound.negotiationId,
          );
        },
        {
          messageErrorDefault: 'Erro ao carregar informações do titular.',
        },
      );
    },
    [negotiationById, spreadBeneficiaryToMoveFile],
  );

  useEffect(() => {
    if (!beneficiaryId || !company.id) {
      return;
    }

    loadBeneficiaryHolder(dataToMove.beneficiaryHolderId, company.id);
  }, [beneficiaryId, company.id, dataToMove.beneficiaryHolderId, loadBeneficiaryHolder]);

  React.useEffect(() => {
    if (showDialog !== undefined) {
      showDialog ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [showDialog]);

  return (
    <BoxInfo
      margin={0}
      title='Titular do beneficiário'
      icon='person-outline'
      header={
        useValues.beneficiaryHolderId ? (
          <>
            <Button
              label='Ver mais informações do titular'
              solo
              onClick={() => setShowDialog(!showDialog)}
            />
            <Dialog
              elevation={4}
              instanceEmitter={useRefDialog.current}
              outlined
              rounded
              onClose={() => setShowDialog(false)}
              size={1366}
              persistent
              head={<Text size='larger' textAlign='center' title value='Detalhes do titular' />}
              footer={
                <Grid alignContent='right' gap={8}>
                  <Button
                    label='Fechar'
                    color='colorDefault'
                    onClick={() => setShowDialog(false)}
                  />
                </Grid>
              }>
              <Details holderId={useValues.beneficiaryHolderId} />
            </Dialog>
          </>
        ) : null
      }>
      <GridGroup
        body={[
          {
            default: 100,
            middle: 100,
            component: (
              <SelectBeneficiary
                fixedList={false}
                required
                disabled={beneficiaryId}
                label='Selecione um titular para o beneficiário'
                name='beneficiaryHolderId'
                onBlur={onBlur}
                onChange={(name: string, value: string, selectedBeneficiary: Beneficiary) => {
                  onChange(name, value);
                  loadBeneficiaryHolder(selectedBeneficiary?.id, company.id);
                  setHolderErrorDetails?.([]);
                }}
                useErrors={useErrors}
                useValues={useValues}
                filterParams={{
                  isHolder: true,
                  status: 'active',
                }}
              />
            ),
          },
          {
            default: 50,
            middle: 100,
            component: (
              <SelectValidate
                label='Vínculo com a empresa'
                name='linkBetweenTheHolderAndTheCompany'
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                disabled={!!disableFieldIfFilledIn?.linkBetweenTheHolderAndTheCompany}
                required={
                  dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.DEPENDENT
                }
                data={[
                  {
                    text: 'Funcionário',
                    value: COMPANY_LINK.EMPLOYEE,
                  },
                  {
                    text: 'Sócio',
                    value: COMPANY_LINK.PARTNER,
                  },
                  {
                    text: 'Estagiário',
                    value: COMPANY_LINK.INTERN,
                  },
                  {
                    text: 'Aprendiz',
                    value: COMPANY_LINK.APPRENTICE,
                  },
                  {
                    text: 'Temporário',
                    value: COMPANY_LINK.TEMPORARY,
                  },
                  {
                    text: 'Sem identificação de vínculo',
                    value: COMPANY_LINK.NO_LINK_IDENTIFICATION,
                  },
                ]}
              />
            ),
          },
          {
            default: 50,
            middle: 100,
            component: (
              <TextFieldValidate
                type='cpf'
                mask='999.999.999-99'
                label={MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR}
                name={MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR}
                useValues={useValues}
                useErrors={useErrors}
                onChange={onChange}
                onBlur={onBlur}
                disabled={!!disableFieldIfFilledIn?.[MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]}
                required={
                  dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.DEPENDENT
                }
              />
            ),
          },
        ]}
      />

      <ShowHide transition='slideToDown' visible={holderErrorDetails?.length}>
        <Text
          value='Erros encontrados nas informações do titular'
          title
          margin='16px 0 8px'
          color='danger'
        />

        <DataTable
          isLoading={false}
          showPagination={false}
          elevation={0}
          data={holderErrorDetails.filter((error) => !error.skippable)}
          columns={[
            {
              name: 'Campo',
              path: 'fields',
              format: (error) => getFieldNames(error),
            },
            {
              name: 'Valor encontrado',
              path: 'value',
            },
            {
              name: 'Valor esperado/problema',
              path: 'rule',
              format: (movesValidationErrorDetail: MovesValidationErrorDetail) =>
                translateRulesValidator(
                  movesValidationErrorDetail.rule,
                  movesValidationErrorDetail.details?.expected,
                ) || getErrorDescription(movesValidationErrorDetail),
            },
          ]}
        />
      </ShowHide>
    </BoxInfo>
  );
};

export default AboutBeneficiaryHolderForm;
